import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className='text-black m-3 w-100 container'>
            <div className="col-12">
                <h1 className='text-center fw-bold fs-2'>Privacy Policy</h1>
                <div>
                    <p>Ohoindia is committed to protecting the privacy of its users.
                        This Privacy Policy describes how Ohoindia collects, uses,
                        and shares personal information collected from users of the application.
                    </p>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Information We Collect</h4>
                    <h3 className="heading fs-5">Personal Information</h3>
                    <p>We may collect personal information such as name. email address,
                        and other contact details when you voluntarily provide them to us.
                    </p>
                </div>
                <div >
                    <h3 className="heading fs-5 mt-2">Usage Information</h3>
                    <p>We automatically collect certain information about your device and usage of the application,
                        including but not limited to your IP address, device type, operating system,
                        and browsing behaviour within the apllication.
                    </p>
                </div>
                <div >
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>How we Use Your Information</h4>
                    <p>We may use the information we collect for various purposes, including but not limited to:
                        <div className="list" style={{ marginLeft: '20px' }}>
                            <li>Providing and maintaining the application</li>
                            <li>Improving and customizing the user experience</li>
                            <li>Sending you updated, and other communications related to the application</li>
                            <li>Detecting and preventing fraud or miuse of the application</li>
                        </div>
                    </p>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Sharing Your Information</h4>
                    <p>We may share your information with third-party service providers
                        who assist us in operating our application, conducting our business,
                        or servicing you. We may also share your information when required by
                        law or to protect our rights or the rights to others.
                    </p>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Children's Privacy</h4>
                    <div className="list" style={{ marginLeft: '20px' }}>
                        <li>We do not knowingly collect personal information from children under
                            the age of 18 without verifiable parental consent.</li>
                        <li>If you are a parent or guardian and believe that yout child has
                            provided personal information to us without your consent,
                            please contact us immediately so that we can take appropriate action.</li>
                    </div>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Your Choices</h4>
                    <p>You may choose not to provide certain personal information,
                        but this may limit your ability to use certain features of the application.
                    </p>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Delete Your Personal Data</h4>
                    <div className="list" style={{ marginLeft: '20px' }}>
                        <li>You have the right to delete or request that We assist in deleting the
                            Personal Data that We have collected about You. </li>
                        <li>Our Service may give You the ability to delete certain information about
                            You from within the Service. </li>
                        <li>You may update, amend, or delete Your information at any time by signing
                            in to Your Account, if you have one, and visiting the account settings
                            section that allows you to manage Your personal information.
                            You may also contact Us to request access to, correct, or delete
                            any personal information that You have provided to Us. </li>
                        <li>Please note, however, that We may need to retain certain information
                            when we have a legal obligation or lawful basis to do so. </li>
                    </div>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Security</h4>
                    <p>We take reasonable measures to protect the information we collect from loss,
                        misuse, and unauthorized access, disclosure, alteration and destruction.
                    </p>
                </div>
                <div >
                    <h3 className="fw-bolder fs-2 mt-5">Refund and Cancellation Policy</h3>
                    <p>Thank you for choosing our wellness programs. We are dedicated to providing exceptional
                        services to support your health and well-being. Please carefully review the following terms:</p>

                    <ul style={{ listStyleType: 'number' }} className='p-3 px-5'>
                        <li> <strong>Non-Refundable Policy</strong> <br />
                            Wellness packages are strictly non-refundable once purchased. This policy ensures the
                            proper allocation of resources and the quality of service for all users.</li>

                        <li> <strong>Non-Transferable and Non-Reschedulable</strong> <br />
                            Purchased wellness packages cannot be transferred to another individual or rescheduled under any circumstance</li>

                        <li> <strong>No Cancellations Accepted</strong> <br />
                            Once a wellness package is purchased, cancellations are not permitted.</li>

                        <li> <strong>Commitment to Policy</strong> <br />
                            By purchasing a wellness package, you acknowledge and agree to these terms.</li>
                    </ul>

                    <p>Your understanding and commitment are greatly appreciated as we strive to provide
                        the best wellness experience possible.</p>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Contact Us</h4>
                    <p>If you have any questions or concerns about our Privacy Policy,
                        please contact us at
                        <a href="https://www.contact@ohoindialife.com" style={{ cursor: 'pointer' }}>
                            contact@ohoindialife.com
                        </a>
                    </p>
                </div>
            </div>
        </div>
    )
};

export default PrivacyPolicy;