import React from 'react';

const RefundPolicy = () => {
    return (
        <div className='text-black m-3 w-100 container'>
            <div className="col-12">
                <h1 className='text-center fw-bold fs-2'>Refund and Cancellation Policy</h1>
                {/* <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Refund Policy</h4>
                    <div className="list" style={{ marginLeft: '20px' }}>
                        <li>We strive to ensure utmost satisfaction with every purchase made through our app.
                            But our policies dosen't offer a Refund Policy to a customer after purchasing a product.</li>
                        <li>We are committed to delivering quality services to our customers 
                            after successful payment for their preferred product purchase.</li>
                        <li>Customers have a privilage to cancel the product before initializing the payment.
                             But they cannot get their amount through refund.</li>
                    </div>
                </div> */}


                <p>Thank you for choosing our wellness programs. We are dedicated to providing exceptional
                    services to support your health and well-being. Please carefully review the following terms:</p>

                <ul style={{ listStyleType: 'number' }} className='p-3 px-5'>
                    <li> <strong>Non-Refundable Policy</strong> <br />
                        Wellness packages are strictly non-refundable once purchased. This policy ensures the
                        proper allocation of resources and the quality of service for all users.</li>

                    <li> <strong>Non-Transferable and Non-Reschedulable</strong> <br />
                        Purchased wellness packages cannot be transferred to another individual or rescheduled under any circumstance</li>

                    <li> <strong>No Cancellations Accepted</strong> <br />
                        Once a wellness package is purchased, cancellations are not permitted.</li>

                    <li> <strong>Commitment to Policy</strong> <br />
                        By purchasing a wellness package, you acknowledge and agree to these terms.</li>
                </ul>

                <p>Your understanding and commitment are greatly appreciated as we strive to provide 
                    the best wellness experience possible.</p>


                {/* <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Cancellation Policy</h4>
                    <div className="list" style={{ marginLeft: '20px' }}>
                        <li>We understand that circumstances may change, and you may need to cancel
                            a subscription or membership. Here's how our cancellation process works:</li>
                        <li>Cancellations can be made before the payment for product was received.
                            To cancel, please reach out to us at
                            <a href="https://www.contact@ohoindialife.com" style={{ cursor: 'pointer' }}>
                                contact@ohoindialife.com
                            </a></li>
                        <li>We're here to assist you throughout the cancellation process.
                            If you encounter any difficulties or have questions,
                            please don't hesitate to reach out to our support team.</li>
                    </div>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Changes to this Privacy Policy</h4>
                    <p>We may update our Privacy Policy from time to time.
                        Any changes will be posted on this page with an updated revision date.
                    </p>
                </div> */}

                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Contact Us</h4>
                    <p>If you have any questions or concerns about our Privacy Policy,
                        please contact us at
                        <a href="https://www.contact@ohoindialife.com" style={{ cursor: 'pointer' }}>
                            contact@ohoindialife.com
                        </a>
                    </p>
                </div>
            </div>
        </div>
    )
};

export default RefundPolicy;